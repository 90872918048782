import { ClientModel } from "..";
class AbstractPost extends ClientModel {
    transformDataForPersist() {
        var _data_title, _data_content;
        const data = {
            ...super.transformDataForPersist()
        };
        data.title = (_data_title = data.title) == null ? void 0 : _data_title.rendered;
        data.content = (_data_content = data.content) == null ? void 0 : _data_content.rendered;
        delete data._links;
        delete data.link;
        return data;
    }
    transformDataForPatch() {
        return this.transformDataForPersist();
    }
}
export { AbstractPost };
