import { commonUrlBuilder, commonRequest } from "./";
/**
 * Create a uri builder and request function for your specific plugin depending
 * on the rest root and additional parameters.
 *
 * @param options
 * @see urlBuilder
 * @see request
 */ function createRequestFactory(options) {
    const urlBuilder = (passOptions)=>commonUrlBuilder({
            ...passOptions,
            options: {
                restNamespace: options.restNamespace,
                restNonce: options.restNonce,
                restQuery: options.restQuery,
                restRoot: options.restRoot,
                restPathObfuscateOffset: options.restPathObfuscateOffset
            }
        });
    const request = (passOptions)=>commonRequest({
            ...passOptions,
            options: {
                restNamespace: options.restNamespace,
                restNonce: options.restNonce,
                restQuery: options.restQuery,
                restRoot: options.restRoot,
                restRecreateNonceEndpoint: options.restRecreateNonceEndpoint,
                restPathObfuscateOffset: options.restPathObfuscateOffset
            }
        });
    return {
        urlBuilder,
        request
    };
}
export { createRequestFactory };
