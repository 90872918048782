import { ClientModel } from "..";
class AbstractCategory extends ClientModel {
    transformDataForPersist() {
        const data = {
            ...super.transformDataForPersist()
        };
        delete data._links;
        delete data.link;
        return data;
    }
    transformDataForPatch() {
        return this.transformDataForPersist();
    }
}
export { AbstractCategory };
