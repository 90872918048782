import { _ as _ts_decorate } from "@swc/helpers/_/_ts_decorate";
import { _ as _ts_metadata } from "@swc/helpers/_/_ts_metadata";
import { observable, computed, set, flow, runInAction } from "mobx";
import { ERouteHttpVerb } from "@devowl-wp/api";
class ClientModel {
    get key() {
        var _this_data;
        return (_this_data = this.data) == null ? void 0 : _this_data[this.annotated.keyId];
    }
    constructor(collection, data = {}){
        this.data = {};
        this.busy = false;
        this.persist = flow(function*(params, settings) {
            if (!this.annotated.create) {
                throw new Error("There is no persist method allowed");
            }
            this.busy = true;
            try {
                const { create: { path, method }, namespace } = this.annotated;
                const response = yield this.annotated.request({
                    location: {
                        path,
                        method: method || ERouteHttpVerb.POST,
                        namespace
                    },
                    request: this.transformDataForPersist(),
                    params: params || {},
                    ...settings || {}
                });
                this.fromResponse(response);
                if (!(settings == null ? void 0 : settings.allowBatchRequest)) {
                    this.collection.entries.set(this.key, this);
                    this.afterPersist();
                }
            } catch (e) {
                console.log(e);
                throw e;
            } finally{
                this.busy = false;
            }
        });
        this.patch = flow(function*(params) {
            if (!this.annotated.patch) {
                throw new Error("There is no patch method allowed");
            }
            this.busy = true;
            try {
                const { patch: { path, method }, namespace } = this.annotated;
                const response = yield this.annotated.request({
                    location: {
                        path,
                        method: method || ERouteHttpVerb.PATCH,
                        namespace
                    },
                    request: this.transformDataForPatch(),
                    params: {
                        ...{
                            [this.annotated.keyId]: this.key
                        },
                        ...params || {}
                    }
                });
                this.fromResponse(response);
                this.afterPatch();
            } catch (e) {
                console.log(e);
                throw e;
            } finally{
                this.busy = false;
            }
        });
        this.delete = flow(function*(params, settings) {
            if (!this.annotated.delete) {
                throw new Error("There is no delete method allowed");
            }
            this.busy = true;
            try {
                const { delete: { path, method }, namespace } = this.annotated;
                const response = yield this.annotated.request({
                    location: {
                        path,
                        method: method || ERouteHttpVerb.DELETE,
                        namespace
                    },
                    params: {
                        ...{
                            [this.annotated.keyId]: this.key
                        },
                        ...params || {}
                    },
                    ...settings || {}
                });
                if (!(settings == null ? void 0 : settings.allowBatchRequest)) {
                    this.collection.entries.delete(this.key);
                    this.afterDelete();
                }
                return response;
            } catch (e) {
                console.log(e);
                throw e;
            } finally{
                this.busy = false;
            }
        });
        setTimeout(()=>{
            if (!this.annotated) {
                console.error("You have not used the @ClientModel.annotate annoation together with this class!");
            }
        }, 0);
        runInAction(()=>{
            this.collection = collection;
            this.data = data;
        });
    }
    static #_ = this.annotate = (annotate)=>(constructor)=>{
            return class extends constructor {
                constructor(...args){
                    super(...args);
                    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
                    this.annotated = annotate;
                }
            };
        };
    fromResponse(response) {
        set(this.data, response);
        return this;
    }
    /**
     * Transform the class-hold data to POSTable data. This can be useful if e. g.
     * one property differs from the response property schema.
     */ transformDataForPersist() {
        return this.data;
    }
    /**
     * Create your conditionals here and return only changed values.
     */ transformDataForPatch() {
        throw new Error("If you want to use patch method, you need to implement transformDataForPatch!");
    }
    afterPersist() {
    // Silence is golden.
    }
    afterPatch() {
    // Silence is golden.
    }
    afterDelete() {
    // Silence is golden.
    }
}
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], ClientModel.prototype, "data", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], ClientModel.prototype, "collection", void 0);
_ts_decorate([
    observable
], ClientModel.prototype, "busy", void 0);
_ts_decorate([
    computed,
    _ts_metadata("design:type", Function),
    _ts_metadata("design:paramtypes", []),
    _ts_metadata("design:returntype", Object)
], ClientModel.prototype, "key", null);
export { ClientModel };
