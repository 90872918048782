/**
 * See PHP file inc/Assets.php.
 */ class BaseOptions {
    /**
     * Convert a slug like "my-plugin" to "myPlugin". This can
     * be useful for library naming (window[""] is bad because the hyphens).
     */ static slugCamelCase(slug) {
        return slug.replace(/-([a-z])/g, (g)=>g[1].toUpperCase());
    }
    /**
     * Get the slug from the current process (webpack) instead of the PHP plugin output.
     * For some cases you need to use that.
     */ static getPureSlug(slug, camelCase) {
        if (camelCase === void 0) camelCase = false;
        return camelCase ? BaseOptions.slugCamelCase(slug) : slug;
    }
}
export { BaseOptions };
