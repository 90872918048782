import { _ as _ts_decorate } from "@swc/helpers/_/_ts_decorate";
import { observable, flow } from "mobx";
import { ERouteHttpVerb } from "@devowl-wp/api";
class ClientCollection {
    constructor(){
        this.entries = new Map();
        this.busy = false;
        this.get = flow(function*(data) {
            const { request, params, clear = false } = data || {};
            this.busy = true;
            try {
                const { path, namespace } = this.annotated;
                const response = yield this.annotated.request({
                    location: {
                        path,
                        method: ERouteHttpVerb.GET,
                        namespace
                    },
                    request,
                    params
                });
                // Save cookies as models
                clear && this.entries.clear();
                for (const row of response){
                    const instance = this.instance(row);
                    const existing = this.entries.get(instance.key);
                    if (!existing) {
                        this.entries.set(instance.key, instance);
                    } else {
                        // Update stale data
                        existing.data = instance.data;
                    }
                }
            } catch (e) {
                console.log(e);
                throw e;
            } finally{
                this.busy = false;
            }
        });
        this.getSingle = flow(function*(data) {
            if (!this.annotated.singlePath) {
                throw new Error("There is no getSingle method allowed");
            }
            const { request, params } = data || {};
            this.busy = true;
            try {
                const { singlePath, namespace } = this.annotated;
                const row = yield this.annotated.request({
                    location: {
                        path: singlePath,
                        method: ERouteHttpVerb.GET,
                        namespace
                    },
                    request,
                    params
                });
                // Save cookie as model
                const instance = this.instance(row);
                this.entries.set(instance.key, instance);
            } catch (e) {
                console.log(e);
                throw e;
            } finally{
                this.busy = false;
            }
        });
        setTimeout(()=>{
            if (!this.annotated) {
                console.error("You have not used the @ClientCollection.annotate annoation together with this class!");
            }
        }, 0);
    }
    static #_ = this.annotate = (annotate)=>{
        return (constructor)=>{
            return class extends constructor {
                constructor(...args){
                    super(...args);
                    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
                    this.annotated = annotate;
                }
            };
        };
    };
}
_ts_decorate([
    observable
], ClientCollection.prototype, "entries", void 0);
_ts_decorate([
    observable
], ClientCollection.prototype, "busy", void 0);
export { ClientCollection };
